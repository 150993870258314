<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs9 sm9 md9 lg6 xl5>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-account-circle-outline</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-icon class="ml-2" small>mdi-asterisk</v-icon>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="perfilLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="10" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="perfilLocal.perfil"
                    :error-messages="compPerfilErrors"
                    label="Perfil"
                    required
                    outlined
                    @input="$v.perfilLocal.perfil.$touch()"
                    @blur="$v.perfilLocal.perfil.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSubmit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar()"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

const { mapState, mapActions } = createNamespacedHelpers("adminPerfis");

export default {
  mixins: [validationMixin],
  validations: {
    perfilLocal: {
      perfil: { required },
      campo: { required },
    },
  },

  data: () => ({
    tituloForm: "",
    perfilLocal: {},
  }),
  computed: {
    ...mapState({ staPerfilSelecionado: "staPerfilSelecionado" }),

    compPerfilErrors() {
      const errors = [];
      if (!this.$v.perfilLocal.perfil.$dirty) return errors;
      !this.$v.perfilLocal.perfil.required && errors.push("Nome requerida.");
      return errors;
    },
    compCampoErrors() {
      const errors = [];
      if (!this.$v.perfilLocal.campo.$dirty) return errors;
      !this.$v.perfilLocal.campo.required && errors.push("Descricão requerida");
      return errors;
    },
  },
  watch: {
    staPerfilSelecionado(perfilNovo) {
      this.metSincronizar(perfilNovo);
    },
  },
  created() {
    this.metSincronizar(this.staPerfilSelecionado);
    this.staPerfilSelecionado
      ? (this.tituloForm = "Editar Perfil")
      : (this.tituloForm = "Novo Perfil");
  },
  methods: {
    ...mapActions(["actCriarPerfil", "actEditarPerfil"]),

    async metSalvarPerfil() {
      this.perfilLocal.perfil = this.perfilLocal.perfil.trim();

      switch (this.staPerfilSelecionado) {
        case undefined:
          this.actCriarPerfil({ perfil: this.perfilLocal });
          this.$root.snackbar.show({
            type: "success",
            message: "Perfil Criado!",
          });
          break;
        default:
          await this.actEditarPerfil({ perfil: this.perfilLocal });
          this.$root.snackbar.show({
            type: "success",
            message: "Perfil Salvo!",
          });
      }
    },

    metSincronizar(novoPerfil) {
      this.perfilLocal = Object.assign({}, novoPerfil || { id: null });
    },
    metSubmit() {
      this.$v.$touch();
      this.metSalvarPerfil();
    },
    metClear() {
      this.perfilLocal.perfil = this.staPerfilSelecionado.perfil;
      this.perfilLocal.campo = this.staPerfilSelecionado.campo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
